// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-brand-assets-tsx": () => import("./../../../src/pages/brand-assets.tsx" /* webpackChunkName: "component---src-pages-brand-assets-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/contact/success.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-why-tsx": () => import("./../../../src/pages/why.tsx" /* webpackChunkName: "component---src-pages-why-tsx" */),
  "component---src-templates-legal-post-js": () => import("./../../../src/templates/legal-post.js" /* webpackChunkName: "component---src-templates-legal-post-js" */),
  "component---src-templates-support-post-js": () => import("./../../../src/templates/support-post.js" /* webpackChunkName: "component---src-templates-support-post-js" */)
}

